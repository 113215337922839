import "./styles.scss"

import React from "react"

const Content = () => {
  return (
    <section className="faq-content">
      <div className="container-fluid">
        <div className="faq-content__wrapper">
          <div className="faq-content__item">
            <div className="row">
              <div className="col-md-4">
                <h3>Co to jest pożyczka inwestycyjna?</h3>
              </div>
              <div className="col-md-7 offset-md-1">
                Pożyczka inwestycyjna to podjęcie współpracy poprzez inwestycję
                w konkretne przedsięwzięcie deweloperskie. To bezobsługowa forma
                lokowania kapitału i czerpaniu z niego określonych zysków
                uzyskiwanych z marży deweloperskiej.
              </div>
            </div>
          </div>
          <div className="faq-content__item">
            <div className="row">
              <div className="col-md-4">
                <h3>Dlaczego Trust?</h3>
              </div>
              <div className="col-md-7 offset-md-1">
                Grupy kapitałowej Trust Investment przeprowadziła kilkanaście
                przedsięwzięć inwestycyjnych. Posiada kompleksową wiedzę i know
                how w jaki sposób zrealizować i sprzedać rentowną inwestycje.
              </div>
            </div>
          </div>
          <div className="faq-content__item">
            <div className="row">
              <div className="col-md-4">
                <h3>
                  Czy od Inwestora wymagane będzie zaangażowanie czasowe w
                  projekt?
                </h3>
              </div>
              <div className="col-md-7 offset-md-1">
                Poza wizytą u notariusza inwestycja nie wymaga poświęcenia
                większej ilości czasu.
                <br />W przypadku inwestycji poniżej 25 000,00 zł inwestycja
                jest jeszcze prostsza i nie wymaga nawet wizyty u notariusza.
              </div>
            </div>
          </div>
          <div className="faq-content__item">
            <div className="row">
              <div className="col-md-4">
                <h3>Kto może zainwestować w Trust</h3>
              </div>
              <div className="col-md-7 offset-md-1">
                Inwestorem może zostać zarówno osoba fizyczna jak i prawna.
              </div>
            </div>
          </div>
          <div className="faq-content__item">
            <div className="row">
              <div className="col-md-4">
                <h3>
                  Czy Inwestorzy są informowani o bieżących postępach prac
                </h3>
              </div>
              <div className="col-md-7 offset-md-1">
                Oczywiście inwestorzy otrzymują dostęp do raportów kwartalnych
                opisujących zarówno bieżący przebieg prac budowlanych jak i
                informacje dotyczące postępów sprzedaży nieruchomości
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
